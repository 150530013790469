import React from 'react';
import { Box, Grid, SpaceBetween } from '@cloudscape-design/components';
import './header.scss';

/**
 * Props for the PageHeader component.
 */
export interface PageHeaderProps {
  /** The main title of the page header */
  title?: string;
  /** A subtitle to provide additional context */
  subtitle?: string;
  /** A brief description or introductory text */
  description?: string;
}

/**
 * PageHeader component displays a hero-style header for a landing page.
 *
 * This component uses Cloudscape Design System components to create a
 * structured and visually appealing header section. It includes a welcome
 * message, a main title, a subtitle, and a description.
 *
 * @component
 * @example
 * ```jsx
 * <PageHeader
 *   title="My Application"
 *   subtitle="Powerful and Easy to Use"
 *   description="Welcome to the next generation of web applications."
 * />
 * ```
 */
export const PageHeader: React.FC<PageHeaderProps> = ({ title, subtitle, description }) => {
  return (
    <div className="landing-header">
      <Grid
        gridDefinition={[
          {
            colspan: 8,
            offset: { s: 1 },
          },
        ]}
      >
        <SpaceBetween size="s">
          <Box fontSize="body-m">Welcome To</Box>
          <Box variant="h1" fontSize="display-l" fontWeight="bold">
            {title}
          </Box>
          <Box fontSize="heading-xl" fontWeight="light">
            {subtitle}
          </Box>
          <Box fontSize="heading-s">{description}</Box>
        </SpaceBetween>
      </Grid>
    </div>
  );
};

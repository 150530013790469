// src/utils/rumConfig.ts
import React from 'react';
import { useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { useUserAlias } from 'src/common/hooks/useUserAlias';
const APPLICATION_ID = 'cc82a59a-46bb-4261-8ac5-8d4598efaece';
const APPLICATION_VERSION = '1.0,0';
const GUEST_ROLE_ARN = 'arn:aws:iam::024848438855:role/ControllershipHub-RUM-alp-CognitoDefaultUnauthentic-IjFnmijDDowF';
const IDENTITY_POOL_ID = 'us-east-1:130f4df4-bea5-451f-b254-8928db1d8b0f';
const RUM_ENDPOINT = 'https://dataplane.rum.us-east-1.amazonaws.com';
const APPLICATION_REGION: string = 'us-east-1';

let awsRum: AwsRum | null = null;

interface RUMProviderProps {
  children: JSX.Element;
}

/**
 * Initializes Amazon CloudWatch RUM (Real User Monitoring) for the application.
 * This function sets up real-time monitoring of user interactions, performance metrics,
 * and error tracking across the application.
 *
 * @param user - The Cognito user object for authenticated users, undefined for guests
 * @param alias - The user's alias/identifier used for session tracking
 *
 */
export const RUMProvider = ({ children }: RUMProviderProps): JSX.Element => {
  const { user } = useAuthenticator((context) => [context.user]);
  const { alias } = useUserAlias();

  useEffect(() => {
    if (!awsRum) {
      initializeRUM(user as CognitoUser | undefined, alias);
    } else if (user) {
      awsRum.addSessionAttributes({
        isAuthenticated: 'true',
        userAlias: alias,
      });
    }
  }, [user, alias]);

  return <>{children}</>;
};

/**
 * Initializes Amazon CloudWatch RUM (Real User Monitoring) for the application
 *
 * @param user - The Cognito user object if authenticated, undefined if not
 * @param alias - The user's alias or identifier
 *
 * Configuration includes:
 * - Session tracking for both authenticated and guest users
 * - Secure cookie settings for user tracking
 * - Complete telemetry collection
 * - X-Ray tracing integration
 */
function initializeRUM(user: CognitoUser | undefined, alias: string) {
  const config: AwsRumConfig = {
    // Track user authentication status and alias
    sessionAttributes: {
      isAuthenticated: user ? 'true' : 'false',
      userAlias: alias || 'guest',
    },

    // Secure cookie configuration for user session tracking
    cookieAttributes: {
      domain: window.location.hostname, // Current domain only
      path: '/', // Available across all paths
      sameSite: 'Lax', // Moderate security for cross-site requests
      secure: true, // HTTPS only
      unique: true, // Unique identifier per user
    },

    // Monitoring configuration
    sessionSampleRate: 1, // Monitor 100% of sessions
    guestRoleArn: GUEST_ROLE_ARN, // IAM role for unauthenticated users
    identityPoolId: IDENTITY_POOL_ID, // Cognito Identity Pool for authentication
    endpoint: RUM_ENDPOINT, // RUM service endpoint

    // Enable all available telemetry types
    telemetries: [
      'performance', // Page load times, web vitals
      'errors', // JavaScript errors and exceptions
      'http', // API calls and responses
      'interaction', // User clicks and navigation
    ],

    allowCookies: true, // Enable cookie-based user tracking
    enableXRay: true, // Enable AWS X-Ray tracing integration
  };

  try {
    // Initialize RUM with application details and configuration
    awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);

    // Capture unhandled promise rejections for error monitoring
    window.addEventListener('unhandledrejection', (event) => {
      awsRum?.recordError(event.reason);
    });
  } catch (error) {
    console.error('Failed to initialize CloudWatch RUM:', error);
  }
}

// Export helper functions to use throughout our application
export const recordCustomEvent = (name: string, data?: Record<string, any>): void => {
  try {
    if (awsRum) {
      if (data) {
        awsRum.recordEvent(name, data);
      } else {
        awsRum.recordEvent(name, {});
      }
    }
  } catch (error) {
    console.error('Failed to record custom event:', error);
  }
};

export const recordError = (error: Error): void => {
  try {
    awsRum?.recordError(error);
  } catch (err) {
    console.error('Failed to record error:', err);
  }
};

export const addSessionAttributes = (
  attributes: Record<string, string | number | boolean>
): void => {
  try {
    awsRum?.addSessionAttributes(attributes);
  } catch (error) {
    console.error('Failed to add session attributes:', error);
  }
};

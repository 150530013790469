import React, { useCallback, useState } from 'react';
import {
  PropertyFilterProps,
  NonCancelableCustomEvent,
  TableProps,
  FlashbarProps,
  ButtonDropdownProps,
} from '@cloudscape-design/components';
import {
  AttributeMetadata,
  ControlAttributesMetadata,
  RecordAttributes,
} from '@amzn/chub-model-typescript-client';
import { PreferencesType } from 'src/components/pages/ControlSummaryTable';
import { ApiCallProps } from 'src/utils/ApiCall';
import { ApiPaths } from 'src/common/config/ApiConstants';

/**
 * Creates a handler function for confirming preference changes
 *
 * @param {function} setPreferences - Function to update preferences
 * @param {function} setItemsPerPage - Function to update items per page
 * @param {function} setVisibleColumns - Function to update visible columns
 * @param {function} setCurrentPage - Function to update current page
 * @param {any[]} columnDefinitions - Array of column definitions
 * @returns {function} A function that handles preference confirmation events
 */
export const getHandleConfirm = (
  setPreferences: (prefs: PreferencesType) => void,
  setItemsPerPage: (size: number) => void,
  setVisibleColumns: (columns: string[]) => void,
  setCurrentPage: (page: number) => void,
  columnDefinitions: any[]
) => {
  return (event: NonCancelableCustomEvent<PreferencesType>) => {
    const newPreferences = event.detail;
    setPreferences(newPreferences);

    if (newPreferences.pageSize) {
      setItemsPerPage(newPreferences.pageSize);
    }

    if (newPreferences.contentDisplay) {
      const columnOrderMap = new Map(
        newPreferences.contentDisplay.map((item, index) => [item.id, index])
      );
      const newVisibleColumns = columnDefinitions
        .filter((col) =>
          newPreferences.contentDisplay!.some((item) => item.id === col.id && item.visible)
        )
        .sort((a, b) => (columnOrderMap.get(a.id) ?? 0) - (columnOrderMap.get(b.id) ?? 0))
        .map((col) => col.id);

      setVisibleColumns(newVisibleColumns);
    } else {
      setVisibleColumns(columnDefinitions.map((column) => column.id));
    }
    setCurrentPage(1);
  };
};

/**
 * Gets the items for the current page based on pagination settings
 *
 * @param {number} currentPage - The current page number
 * @param {number} itemsPerPage - The number of items to display per page
 * @param {Record<string, any>[]} filteredItems - The array of filtered items
 * @returns {Record<string, any>[]} The slice of items for the current page
 */
export const getCurrentPageItems = (
  currentPage: number,
  itemsPerPage: number,
  filteredItems: Record<string, any>[] // Replace 'any' with the correct type
) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return filteredItems.slice(startIndex, endIndex);
};

/**
 * Filters an array of items based on a filter query
 *
 * @param {any[]} items - The array of items to filter
 * @param {Object} filterQuery - The filter query object
 * @param {readonly PropertyFilterProps.Token[]} filterQuery.tokens - The filter tokens
 * @returns {any[]} The filtered array of items
 */
export const filterItems = (
  items: any[],
  filterQuery: { tokens: readonly PropertyFilterProps.Token[] }
) => {
  // If no filter tokens, return all items
  if (filterQuery.tokens.length === 0) {
    return items;
  }

  // Filter items based on all filter tokens
  return items.filter((item) =>
    filterQuery.tokens.every((token) => {
      const propertyToken = token as PropertyFilterProps.Token;
      if (propertyToken.propertyKey && propertyToken.operator) {
        const itemValue = item[propertyToken.propertyKey];

        // Apply different filtering logic based on the operator
        switch (propertyToken.operator) {
          case ':': // Contains
            return (
              itemValue &&
              itemValue.toString().toLowerCase().includes(propertyToken.value.toLowerCase())
            );
          case '!:': // Does not contain
            return (
              !itemValue ||
              !itemValue.toString().toLowerCase().includes(propertyToken.value.toLowerCase())
            );
          case '=': // Equals
            return itemValue === propertyToken.value;
          case '!=': // Not equals
            return itemValue !== propertyToken.value;
          default:
            return true;
        }
      }
      return true;
    })
  );
};

/**
 * Evaluates a dynamic expression with variable substitution
 *
 * @param {string} expression - The expression to evaluate
 * @param {Record<string, any>} originalItem - The original item object
 * @param {string} columnId - The ID of the column being edited
 * @param {any} newValue - The new value for the edited column
 * @returns {boolean} The result of the evaluated expression
 */
export const evaluateExpression = (
  expression: string,
  originalItem: Record<string, any>,
  columnId: string,
  newValue: any
): boolean => {
  // Regular expression to match ${...} patterns
  const regex = /\$\{([^}]+)\}/g;

  // Replace ${...} patterns with actual values
  const evaluatedExpression = expression.replace(regex, (match, key) => {
    const value = key.trim() === columnId ? newValue : originalItem[key.trim()];
    return JSON.stringify(value);
  });

  try {
    // Evaluate the expression using a new Function
    return new Function(`return ${evaluatedExpression}`)();
  } catch (error) {
    console.error('Error evaluating expression:', error);
    return false;
  }
};

/**
 * Custom hook to manage table preferences
 *
 * @param {ControlAttributesMetadata | null} controlAttributesData - Metadata for control attributes
 * @param {any[]} columnDefinitions - Array of column definitions
 * @returns {[PreferencesType, React.Dispatch<React.SetStateAction<PreferencesType>>]} A tuple containing the preferences state and its setter function
 */
export const getPreferences = (
  controlAttributesData: ControlAttributesMetadata | null,
  columnDefinitions: any[]
) => {
  return useState<PreferencesType>(() => {
    // Extract view preferences from control attributes data
    const viewPreferences = controlAttributesData?.viewPreferences;

    // Generate content display configuration from attributes metadata
    const contentDisplay =
      controlAttributesData?.attributesMetadata?.map((attribute: AttributeMetadata) => ({
        id: attribute.name ?? '',
        visible: attribute.isVisibleByDefault ?? false,
      })) ?? [];

    // Parse sticky columns configuration, defaulting to no sticky columns if not provided
    const stickyColumns = JSON.parse(viewPreferences?.stickyColumns ?? '{"first": 0, "last": 0}');

    // Return the initial preferences state
    return {
      // Page size, defaulting to 10 if not specified
      pageSize: viewPreferences?.pageSize ?? 10,

      // Whether to wrap lines, defaulting to true if not specified
      wrapLines: viewPreferences?.wrapLines ?? true,

      // Sticky columns configuration
      stickyColumns: { first: stickyColumns.first, last: stickyColumns.last },

      // Content display configuration
      contentDisplay:
        contentDisplay.length > 0
          ? contentDisplay
          : columnDefinitions.map((column) => ({
              id: column.id,
              visible: true, // Default to true if not specified in attributesMetadata
            })),
    };
  });
};

/**
 * Creates a handler function for submitting edits in a table
 *
 * @param {ControlAttributesMetadata | null} controlAttributesData - Metadata for control attributes
 * @param {function} setModalVisible - Function to set modal visibility
 * @param {function} setModalContent - Function to set modal content
 * @param {React.Dispatch<React.SetStateAction<Record<string, any>[]>>} setItems - Function to update all items
 * @param {React.Dispatch<React.SetStateAction<Record<string, any>[]>>} setFilteredItems - Function to update filtered items
 * @param {string} alias - User alias for audit formatting
 * @returns {function} A function that handles the submission of edits
 */
export const getHandleSubmitEdit = (
  controlAttributesData: ControlAttributesMetadata | null,
  setModalVisible: (visible: boolean) => void,
  setModalContent: (content: { title: string; body: string }) => void,
  setItems: React.Dispatch<React.SetStateAction<Record<string, any>[]>>,
  setFilteredItems: React.Dispatch<React.SetStateAction<Record<string, any>[]>>,
  alias: string
) => {
  return async (
    item: Record<string, any>,
    column: TableProps.ColumnDefinition<Record<string, any>>,
    newValue: any
  ) => {
    // Find the attribute metadata for the edited column
    const attribute = controlAttributesData?.attributesMetadata?.find(
      (attr) => attr.name === column.id
    );

    // Perform validation if a validation expression exists
    if (attribute?.validationExpression) {
      // Check for Cost Center length (specific validation)
      if (newValue && newValue.length > 6) {
        //TODO: This expression needs to be pushed to backend
        setModalVisible(true);
        setModalContent({
          title: 'Error: Invalid Input',
          body: 'Cost Center must not exceed 6 characters. Please enter a valid Cost Center.',
        });
        throw new Error('Invalid Cost Center length');
      }

      // Evaluate the validation expression
      const isValid = evaluateExpression(
        attribute.validationExpression,
        item,
        column.id as string,
        newValue
      );

      // Show appropriate modal based on validation result
      if (isValid) {
        setModalVisible(true);
        setModalContent({
          title: 'Warning : Action Required',
          body: 'Cost Center has been updated.\n Please ensure any relevant comments are  added in the "Cost Center Update Comments" field. \n Review the "Finance Comments" column due to the Cost Center change.',
        });
      } else {
        setModalVisible(true);
        setModalContent({
          title: 'Warning : Action Required',
          body: 'Cost Center has been updated.\n Please ensure any relevant comments are  added in the "Cost Center Update Comments" field.',
        });
      }
    }

    // Function to update an individual item
    const updateItem = (prevItem: Record<string, any>) => {
      if (prevItem === item) {
        let updatedValue = newValue;

        // Apply audit formatting if enabled
        if (attribute?.enableAuditFormat) {
          const timestamp = new Date().toISOString();
          updatedValue = `[${alias}@][${timestamp}]:: ${newValue}`.trim();
        }

        // Create updated item with new value
        const updatedItem = {
          ...prevItem,
          [column.id as keyof typeof item]: updatedValue,
        };

        // Store original item for tracking changes
        if (!prevItem._originalItem) {
          updatedItem._originalItem = { ...item };
        }

        return updatedItem;
      }
      return prevItem;
    };

    // Update both all items and filtered items
    setItems((prevItems) => prevItems.map(updateItem));
    setFilteredItems((prevItems) => prevItems.map(updateItem));
  };
};

/**
 * Creates a handler function for submitting all edits in a table
 *
 * @param {ControlAttributesMetadata | null} controlAttributesData - Metadata for control attributes
 * @param {Record<string, any>[]} items - Current items in the table
 * @param {Record<string, any>[]} originalItems - Original (unedited) items in the table
 * @param {string} controlId - ID of the control
 * @param {function} apiCall - Function to make API calls
 * @param {React.Dispatch<React.SetStateAction<Record<string, any>[]>>} setItems - Function to update all items
 * @param {React.Dispatch<React.SetStateAction<Record<string, any>[]>>} setFilteredItems - Function to update filtered items
 * @param {function} setShowBanner - Function to show/hide a banner
 * @param {function} setIsDirty - Function to set the dirty state
 * @returns {function} An async function that handles the submission of all edits
 */
export const getHandleSubmit = (
  controlAttributesData: ControlAttributesMetadata | null,
  items: Record<string, any>[],
  originalItems: Record<string, any>[],
  controlId: string,
  apiCall: <T>(props: ApiCallProps) => Promise<T>,
  setItems: React.Dispatch<React.SetStateAction<Record<string, any>[]>>,
  setFilteredItems: React.Dispatch<React.SetStateAction<Record<string, any>[]>>,
  setShowBanner: (show: boolean) => void,
  setIsDirty: (dirty: boolean) => void
) => {
  return async () => {
    try {
      // Check if control dataset key is available
      if (!controlAttributesData || !controlAttributesData.controlDatasetKey) {
        console.error('Control dataset key is not available');
        return;
      }
      const controlDatasetKey = controlAttributesData.controlDatasetKey;
      const controlKeyToUpdatesMap: { [key: string]: RecordAttributes[] } = {};

      // Iterate through items to find changes
      items.forEach((editedItem, index) => {
        const originalItem = originalItems[index];
        let recordAttributes: RecordAttributes[] = [];

        // Compare each field in the item
        Object.entries(editedItem).forEach(([key, value]) => {
          const originalValue = originalItem[key];

          // Add to recordAttributes if value has changed
          if (value !== originalValue && value !== '' && key !== '_originalItem') {
            recordAttributes.push({ name: key, value: value.toString() });
          }
        });

        // If changes exist, add to controlKeyToUpdatesMap
        if (recordAttributes.length > 0) {
          const controldatasetkey = editedItem[controlDatasetKey];
          if (controldatasetkey) {
            controlKeyToUpdatesMap[controldatasetkey] = recordAttributes;
          }
        }
      });

      // If no changes, set isDirty to false and return
      if (Object.keys(controlKeyToUpdatesMap).length === 0) {
        setIsDirty(false);
        return;
      }

      // Prepare and send API request
      const requestBody = { controlKeyToUpdatesMap };
      const endpoint = ApiPaths.updateControlData + `/${controlId}`;
      const method = 'PATCH';

      const response = await apiCall<Response>({ endpoint, method, body: requestBody });

      // Remove _originalItem from all items
      setItems((prevItems) => prevItems.map(({ _originalItem, ...item }) => item));
      setFilteredItems((prevItems) => prevItems.map(({ _originalItem, ...item }) => item));

      // Show success banner briefly
      setShowBanner(true);
      setTimeout(() => {
        setShowBanner(false);
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error('Error submitting edited items:', error);
    }
    // Set isDirty to false after submission attempt
    setIsDirty(false);
  };
};

/**
 * Custom hook to create an undo handler for table edits
 *
 * @param {React.Dispatch<React.SetStateAction<any[]>>} setItems - Function to update all items
 * @param {React.Dispatch<React.SetStateAction<any[]>>} setFilteredItems - Function to update filtered items
 * @returns {(item: Record<string, any>) => void} A memoized function that handles undoing changes to a specific item
 */
export const getHandleUndo = (
  setItems: React.Dispatch<React.SetStateAction<any[]>>,
  setFilteredItems: React.Dispatch<React.SetStateAction<any[]>>
) => {
  return useCallback(
    (item: Record<string, any>) => {
      // Check if the item has an original version stored
      if (item._originalItem) {
        const originalItem = item._originalItem;

        // Update the main items list
        setItems((prevItems) =>
          prevItems.map((prevItem) => (prevItem === item ? originalItem : prevItem))
        );

        // Update the filtered items list
        setFilteredItems((prevItems) =>
          prevItems.map((prevItem) => (prevItem === item ? originalItem : prevItem))
        );
      }
    },
    [setItems, setFilteredItems]
  );
};

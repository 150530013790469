import { TopNavigation, TopNavigationProps } from '@cloudscape-design/components';
import Logo from '../../images/ControllershipHubLogo.svg';
import FinergyFloatingMenu from '../layout/FinergyFloatingMenu';
import { useControls } from 'src/common/ControlContext';
import { ControlMetadata } from '@amzn/chub-model-typescript-client';

/**
 * Props for the TopNav component
 * @interface TopNavProps
 */
export interface TopNavProps {
  /** User's alias */
  userAlias?: string;
  /** Link to the about page */
  aboutLink?: string;
  /** Link to report issues */
  issueLink?: string;
}

/**
 * TopNav Component
 *
 * This component renders the top navigation bar for the Controllership Hub application.
 * It includes a logo, user profile dropdown, contact us button, and about button.
 *
 * @param {TopNavProps} props - The props for the TopNav component
 * @returns {JSX.Element} The rendered TopNav component
 */
export default function TopNav(props: TopNavProps) {
  const { controls } = useControls();

  /**
   * Creates the user profile dropdown utility
   * @param {string} [userAlias] - The user's alias
   * @returns {TopNavigationProps.Utility} The user profile dropdown configuration
   */
  const userProfileDropdown = function (userAlias?: string) {
    return {
      type: 'button',
      text: userAlias,
      description: userAlias + '@amazon.com',
      iconName: 'user-profile',
      external: true,
      target: '_blank',
      href: `https://phonetool.amazon.com/users/${userAlias}`,
      iconUrl: `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userAlias}`,
      items: [],
    } as TopNavigationProps.Utility;
  };

  /**
   * Creates the contact us utility
   * @param {string} [issueLink] - The link to report issues
   * @returns {TopNavigationProps.Utility} The contact us button configuration
   */
  const contactUs = function (issueLink?: string) {
    return {
      type: 'button',
      iconName: 'ticket',
      href: issueLink,
      items: [],
      target: '_blank',
    } as TopNavigationProps.Utility;
  };

  /**
   * Creates the about ControllershipHub utility
   * @param {string} [aboutLink] - The link to the about page
   * @returns {TopNavigationProps.Utility} The about button configuration
   */
  const aboutCHUB = function (aboutLink?: string) {
    return {
      type: 'button',
      iconName: 'status-info',
      href: aboutLink,
      items: [],
      target: '_blank',
    } as TopNavigationProps.Utility;
  };

  /**
   * Groups controls by their controlType.
   * @param {ControlMetadata[]} controls - Array of control objects.
   * @returns {Record<string, ControlMetadata[]>} An object where keys are control types and values are arrays of controls of that type.
   */
  const groupedControls = controls.reduce(
    (controlsByType, control) => {
      const { controlType } = control;
      if (controlType !== undefined) {
        // Add this type guard
        controlsByType[controlType] = controlsByType[controlType] || [];
        controlsByType[controlType].push(control);
      }
      return controlsByType;
    },
    {} as Record<string, ControlMetadata[]>
  );

  /**
   * Converts a string into a URL-friendly slug.
   * @param {string} str - The input string to be converted.
   * @returns {string} A lowercase string with spaces replaced by hyphens.
   */
  const slugify = (str: string) => str.replace(/\s/g, '-').toLowerCase();

  /**
   * Transforms grouped controls into a format suitable for dropdown menus.
   * @returns {Array<{type: 'menu-dropdown', text: string, items: Array<{id: string, text: string, href: string}>}>}
   * An array of menu-dropdown objects, each representing a control type with its associated controls.
   */
  const controlMenus = Object.entries(groupedControls).map(([controlTypeKey, controls]) => {
    let controlTypeName: string;
    switch (controlTypeKey) {
      case 'OTHER':
        controlTypeName = 'Procurement';
        break;
      case 'FIXED_ASSETS':
        controlTypeName = 'Fixed Assets';
        break;
      default:
        controlTypeName = controlTypeKey;
        break;
    }
    const controlType = controlTypeKey === 'OTHER' ? 'SpendCentral' : controlTypeKey; //TODO: Remove OTHER and handle SpendCentral type

    return {
      type: 'menu-dropdown' as const,
      text: controlTypeName,
      items: controls.map((control) => ({
        id: control.controlId || '',
        text: control.controlName || '',
        href:
          controlType === 'SpendCentral'
            ? `/${slugify(controlType)}`
            : `/${slugify(controlType)}/${control?.controlId}`,
      })),
    };
  });

  /**
   * Creates the main Controllership Tasks dropdown menu.
   * @returns {TopNavigationProps.Utility} An object representing the Controllership Tasks dropdown menu.
   */
  const controllershipHub = function () {
    return {
      type: 'menu-dropdown',
      text: 'Controllership Tasks',
      href: '/',
      items: controlMenus,
    } as TopNavigationProps.Utility;
  };

  return (
    <div>
      <FinergyFloatingMenu />
      <TopNavigation
        identity={{
          href: '/',
          logo: {
            src: Logo,
          },
        }}
        utilities={[
          controllershipHub(),
          contactUs(props.issueLink),
          aboutCHUB(props.aboutLink),
          userProfileDropdown(props.userAlias),
        ]}
      />
    </div>
  );
}

import React from 'react';
import { CollectionPreferences, NonCancelableCustomEvent } from '@cloudscape-design/components';
import { PreferencesType } from 'src/components/pages/ControlSummaryTable';

interface CustomCollectionPreferencesProps {
  preferences: PreferencesType;
  handleConfirm: (event: NonCancelableCustomEvent<PreferencesType>) => void;
  columnDefinitions: any[];
}

/**
 * CustomCollectionPreferences Component
 *
 * This component renders a CollectionPreferences component with custom configurations
 * for table preferences including page size, line wrapping, column visibility, and sticky columns.
 *
 * @param {CustomCollectionPreferencesProps} props - The props for the component
 * @returns {JSX.Element} A CollectionPreferences component with custom configurations
 */
const CustomCollectionPreferences: React.FC<CustomCollectionPreferencesProps> = ({
  preferences,
  handleConfirm,
  columnDefinitions,
}) => {
  return (
    <CollectionPreferences
      title="Preferences"
      confirmLabel="Confirm"
      cancelLabel="Cancel"
      preferences={preferences}
      onConfirm={handleConfirm}
      pageSizePreference={{
        title: 'Page size',
        options: [
          //TODO: Pull from the backend config
          { value: 300, label: '300 resources' },
          { value: 400, label: '400 resources' },
          { value: 500, label: '500 resources' },
        ],
      }}
      wrapLinesPreference={{
        label: 'wrap_lines',
        description: 'wrap lines',
      }}
      contentDisplayPreference={{
        title: 'Column preferences',
        options: columnDefinitions
          .filter((column) => column.id !== 'undo') // Exclude the undo column
          .map((column) => ({
            id: column.id,
            label: column.header,
            editable: true, // All columns can be toggled now
          })),
      }}
      stickyColumnsPreference={{
        firstColumns: {
          title: 'Stick first column(s)',
          description:
            'Keep the first column(s) visible while horizontally scrolling the table content.',
          options: [
            //TODO: Pull from the backend config
            { label: 'None', value: 0 },
            { label: 'First two column', value: 2 },
            { label: 'First five columns', value: 5 },
            { label: 'First eight columns', value: 8 },
          ],
        },
        lastColumns: {
          title: 'Stick last column',
          description:
            'Keep the last column visible while horizontally scrolling the table content.',
          options: [
            { label: 'None', value: 0 },
            { label: 'Last column', value: 1 },
          ],
        },
      }}
    />
  );
};

export default CustomCollectionPreferences;

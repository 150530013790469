import React from 'react';
import { Box, Link } from '@cloudscape-design/components';
import parse, { domToReact, HTMLReactParserOptions, Element } from 'html-react-parser';

/**
 * Props for the ControlDescription component.
 */
interface ControlDescriptionProps {
  /** The HTML string description to be parsed and rendered. */
  description: string;
}

/**
 * ControlDescription component renders a formatted description with custom styling.
 * It parses HTML content and converts it to React components using Cloudscape Design System.
 */
function ControlDescription({ description }: ControlDescriptionProps) {
  /**
   * Options for HTML parsing and conversion to React components.
   */
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element) {
        return convertElementToComponent(domNode, options);
      }
    },
  };

  /**
   * Converts a DOM element to a corresponding React component.
   */
  const convertElementToComponent = (element: Element, options: HTMLReactParserOptions) => {
    switch (element.name) {
      case 'p':
        return <Box variant="p">{domToReact(element.children, options)}</Box>;
      case 'h1':
        return <Box variant="h1">{domToReact(element.children, options)}</Box>;
      case 'h2':
        return <Box variant="h2">{domToReact(element.children, options)}</Box>;
      case 'h3':
        return <Box variant="h3">{domToReact(element.children, options)}</Box>;
      case 'ul':
      case 'ol':
        return <Box margin={{ left: 'l' }}>{domToReact(element.children, options)}</Box>;
      case 'li':
        return <Box>• {domToReact(element.children, options)}</Box>;
      case 'a':
        return (
          <Link href={element.attribs.href} external externalIconAriaLabel="Opens in a new tab">
            {domToReact(element.children, options)}
          </Link>
        );
      default:
        return element;
    }
  };

  /**
   * Converts plain text URLs to hyperlinks.
   */
  const convertUrlsToLinks = (text: string): string => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => `<a href="${url}">${url}</a>`);
  };

  // Convert plain text URLs to hyperlinks before parsing
  const processedDescription = convertUrlsToLinks(description);

  return <Box padding="s">{parse(processedDescription, options)}</Box>;
}

export default ControlDescription;

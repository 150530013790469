import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  AppLayout,
  Container,
  Grid,
  Box,
  Link,
  Cards,
  SpaceBetween,
  ColumnLayout,
  Header,
  Button,
} from '@cloudscape-design/components';
import TopNav from '../navigation/TopNav';
import ToolsNav from '../navigation/ToolsNav';
import { PageHeader } from '../layout/PageHeader';
import { useUserAlias } from '../../common/hooks/useUserAlias';
import { useControls } from 'src/common/ControlContext';

/**
 * Converts a string into a URL-friendly slug.
 * @param {string} str - The input string to be converted.
 * @returns {string} A lowercase string with spaces replaced by hyphens.
 */
const slugify = (str: string) => str.replace(/\s/g, '-').toLowerCase();

/**
 * Landing Component
 *
 * This component serves as the main landing page for the Controllership Hub application.
 * It includes the top navigation, tools navigation, and main content area.
 *
 * @returns {JSX.Element} The rendered Landing component
 */
export default function Landing(): JSX.Element {
  const { alias } = useUserAlias();
  // Get the list of controls using a custom hook
  const { controls } = useControls();
  const [toolsToggle, setToolsToggle] = useState<boolean>(true);

  return (
    <>
      <div id="b">
        <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
          <TopNav
            userAlias={alias}
            issueLink="https://sim.amazon.com/issues/create?assignedFolder=cb133b70-fac3-42e7-86f2-575d040a086d"
            aboutLink="https://w.amazon.com/bin/view/WWCF_IPAT/FinTech/Controllership/Controllership_Hub/"
          />
        </div>
        <Outlet />
      </div>
      <AppLayout
        navigationHide={true}
        disableContentPaddings={true}
        disableContentHeaderOverlap={true}
        tools={<ToolsNav />}
        maxContentWidth={Number.MAX_VALUE}
        toolsWidth={400}
        onToolsChange={async () => {
          setToolsToggle(!toolsToggle);
        }}
        content={
          <>
            <PageHeader
              title="Controllership Hub"
              subtitle="Your one-stop location for consolidated and streamlined controllership work within the WW Amazon Stores Finance."
              description="Finance Controllership Hub brings together the disparate controllership tools, streamlining the once-fragmented world of controllership into a unified and efficient ecosystem. It brings ease, efficiency, and convenience to controllership monitoring, reporting, and task tracking within World Wide Amazon Stores Finance. It is designed to transform how finance controllership processes are managed, monitored, and automated."
            />

            <Container>
              <SpaceBetween size="l">
                <Box>
                  <Container
                    header={<Header variant="h2">Getting Started</Header>}
                    disableContentPaddings={false}
                  >
                    <ColumnLayout borders="horizontal">
                      <Link
                        external
                        variant="primary"
                        href="https://w.amazon.com/bin/view/WWCF_IPAT/FinTech/Controllership/Controllership_Hub/"
                      >
                        What is Finance Controllership Hub?
                      </Link>
                    </ColumnLayout>
                  </Container>
                </Box>
                <Container>
                  <Box padding={{ vertical: 's', horizontal: 'l' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <h2 style={{ marginRight: '8px' }}>Controllership Tasks</h2>
                    </div>
                  </Box>
                  <Grid gridDefinition={[{ colspan: 8 }, { colspan: 6 }]}>
                    <Cards
                      cardDefinition={{
                        header: (item) => <h3>{item.title}</h3>,
                        sections: [
                          {
                            id: 'content',
                            content: (item) => (
                              <>
                                <p>{item.content}</p>
                                {item.controls && (
                                  <div>
                                    <SpaceBetween size={'l'}>
                                      {item.controls.map((control, index) => (
                                        <Button
                                          key={index}
                                          variant="primary"
                                          href={`/${slugify(control.controlType!)}/${control.controlId}`}
                                        >
                                          {control.controlName}
                                        </Button>
                                      ))}
                                    </SpaceBetween>
                                  </div>
                                )}
                              </>
                            ),
                          },
                        ],
                      }}
                      items={[
                        {
                          title: 'Fixed Asset Controllership',
                          content: 'Perform Fixed Asset Control Executions for below controls',
                          controls: controls.filter(
                            (control) => control.controlType === 'FIXED_ASSETS'
                          ),
                        },
                        {
                          title: 'Reports & Dashboards',
                          content:
                            'World Wide Amazon Stores Finance (WWASF) Fixed Asset Single Source of Truth (FA SSOT); a comprehensive data repository that contains essential financial and operational information related to fixed assets from ideation to retirement. FA SSOT datasets contain financial details from the Fixed Asset Subledger (FASL), Construction In Progress (CIP) ledger, operational data from Enterprise Asset Management (EAM) systems, procurement system (Coupa), and the general ledger.',
                          controls: controls.filter((control) => control.controlType === 'Reports'),
                        },
                      ]}
                    />
                  </Grid>
                  <Container>
                    <Box padding={{ vertical: 's', horizontal: 'l' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h2 style={{ marginRight: '8px' }}>Key Capabilites</h2>
                      </div>
                    </Box>

                    <Grid gridDefinition={[{ colspan: 8 }, { colspan: 6 }]}>
                      <Cards
                        cardDefinition={{
                          header: (item) => <h3>{item.title}</h3>,
                          sections: [{ id: 'content', content: (item) => <p>{item.content}</p> }],
                        }}
                        items={[
                          {
                            title: 'Centralization',
                            content:
                              'Controllership Hub is a single place to go to assist finance teams in controllership activities.',
                          },
                          {
                            title: 'Unification',
                            content:
                              'Each new controllership automation is introduced as a new capability of a Controllership Hub, instead of creating a new tool.',
                          },
                          {
                            title: 'Visibility',
                            content:
                              "Controllership Hub has persona-based dashboards for holistic monitoring of the control environment's health to validating that the underlying process are working as intended or whether they require improvement. Controllership Hub surfaces insights about control execution facilitated by underlying CAPEX and Leasing tools providing intelligence on defects and cash flow opportunities.",
                          },
                        ]}
                      />
                    </Grid>
                  </Container>
                </Container>
              </SpaceBetween>
            </Container>
          </>
        }
      />
    </>
  );
}

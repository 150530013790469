/**
 * Represents the configuration for a single report.
 */
export interface ReportConfig {
  /** The text to be displayed as the header for this report. */
  headerText: string;
  /** The unique identifier for the QuickSight dashboard associated with this report. */
  dashboardId: string;
}

/**
 * Represents the configuration for a tab in the report page.
 */
export interface TabConfig {
  /** The label to be displayed for this tab. */
  label: string;
  /** A unique identifier for this tab. */
  id: string;
  /** An array of ReportConfig objects representing the reports to be displayed in this tab. */
  reports: ReportConfig[];
}

/**
 * Represents the configuration for an entire report page.
 */
export interface ReportPageConfig {
  /** The AWS account ID associated with the QuickSight dashboards. */
  accountId: string;
  /** The directory alias used in the QuickSight dashboard URLs. */
  directoryAlias: string;
  /** The AWS region where the QuickSight dashboards are hosted. */
  region: string;
  /** The title to be displayed at the top of the report page. */
  title: string;
  /** An array of TabConfig objects representing the tabs to be displayed on this report page. */
  tabs: TabConfig[];
}

/**
 * A record of report page configurations, keyed by report type.
 * Each key represents a different report type (e.g., 'FASSOT'),
 * and its value is the corresponding ReportPageConfig.
 */
const reportConfigs: Record<string, ReportPageConfig> = {
  /**
   * Configuration for FASSOT (Fixed Asset System of Record) reports.
   */
  SSOT: {
    accountId: '571311412775',
    directoryAlias: 'fassot-reports',
    region: 'us-east-1',
    title: 'FASSOT Reports',
    tabs: [
      {
        label: 'Asset Life Cycle',
        id: 'asset',
        reports: [
          { headerText: 'Asset Addition', dashboardId: '38fb979c-1665-4e14-92f0-f54ecf068086' },
          {
            headerText: 'Asset Reclassification',
            dashboardId: 'bbab6fe3-5c25-48de-8464-535376cdbbc3',
          },
          { headerText: 'Asset Transfer', dashboardId: '73a4ce9a-6651-4fb7-bbdf-ad1dd8d06e74' },
          { headerText: 'Asset Retirement', dashboardId: '31ca28d9-cb6d-4831-a443-bcaeef910490' },
        ],
      },
      {
        label: 'Financial Reports',
        id: 'financereports',
        reports: [
          { headerText: 'Reserve Subledger', dashboardId: '3726f476-9829-4fbf-a068-ef1d1d5b5bf5' },
          { headerText: 'Lantern PO Invoice', dashboardId: '38fb979c-1665-4e14-92f0-f54ecf068086' },
        ],
      },
      {
        label: 'CIP',
        id: 'cip',
        reports: [
          { headerText: 'CIP', dashboardId: '35dbfd49-3b57-4c10-bd1e-05ad92830994' },
          {
            headerText: 'CIP to Capitalization',
            dashboardId: 'b49205fa-d6bc-449d-a22c-70f6311ece47',
          },
        ],
      },
    ],
  },
  // Additional report types can be added here following the same structure
};

export default reportConfigs;

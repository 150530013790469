import { Button, Popover, SpaceBetween } from '@cloudscape-design/components';
import React from 'react';

export type TableHeaderWithInfoProps = {
  fieldName: string;
  info: React.ReactNode;
};

/**
 * TableHeaderWithInfo Component
 *
 * This component renders a table header with an additional info button that displays a popover when clicked.
 * It combines a field name with an info icon, providing a way to show additional information about the column.
 *
 * @param {TableHeaderWithInfoProps} props - The props for the component
 * @param {string} props.fieldName - The name of the field to be displayed as the header text
 * @param {React.ReactNode} props.info - The content to be displayed in the popover
 *
 * @returns {JSX.Element} A table header with an info button
 */
export const TableHeaderWithInfo = (props: TableHeaderWithInfoProps) => {
  return (
    <>
      <div color={'Red'}>
        <SpaceBetween size={'xxs'} direction={'horizontal'}>
          <span key="fieldName">{props.fieldName}</span>
          <Popover
            key="popover"
            dismissButton={false}
            renderWithPortal={true}
            triggerType={'custom'}
            content={props.info}
            size={'medium'}
            position={'top'}
          >
            <Button variant={'inline-icon'} iconName={'status-info'} />
          </Popover>
        </SpaceBetween>
      </div>
    </>
  );
};

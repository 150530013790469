import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { PropertyFilter, PropertyFilterProps, Button } from '@cloudscape-design/components';
import { AttributeMetadata, ControlAttributesMetadata } from '@amzn/chub-model-typescript-client';

interface PropertyFilterComponentProps {
  attributesMetadata: AttributeMetadata[];
  onFilterChange: (query: PropertyFilterProps.Query) => void;
  items: Record<string, any>[];
  filteredItems: Record<string, any>[];
  controlAttributeData: ControlAttributesMetadata;
}

/**
 * PropertyFilterComponent
 *
 * This component renders a property filter for filtering items based on their attributes.
 * It manages the filter state, handles filter changes, and persists the filter query in session storage.
 *
 * @param {Object} props - The component props
 * @param {AttributeMetadata[]} props.attributesMetadata - Metadata for the attributes that can be filtered
 * @param {function} props.onFilterChange - Callback function to handle filter changes
 * @param {any[]} props.items - The full list of items to be filtered
 * @param {any[]} props.filteredItems - The current filtered list of items
 * @param {Object} props.controlAttributeData - Control attribute data containing default filters
 */
export const PropertyFilterComponent: React.FC<PropertyFilterComponentProps> = ({
  attributesMetadata,
  onFilterChange,
  items,
  filteredItems,
  controlAttributeData,
}) => {
  const [query, setQuery] = useState<PropertyFilterProps.Query>(() => {
    const savedQuery = sessionStorage.getItem('savedFilterQuery');
    return savedQuery && savedQuery != '{"tokens":[],"operation":"and"}'
      ? JSON.parse(savedQuery)
      : JSON.parse(controlAttributeData.defaultFilters!);
  });

  const { filteringProperties, filteringOptions } = useMemo(() => {
    const properties: PropertyFilterProps.FilteringProperty[] = [];
    const options: PropertyFilterProps.FilteringOption[] = [];

    attributesMetadata
      .filter((attr): attr is AttributeMetadata & { name: string } => !!attr.name)
      .forEach((attr) => {
        if (attr.isOverridable) {
          const uniqueValues = Array.from(
            new Set(items.map((item) => item[attr.name] || '').filter(Boolean))
          );
          properties.splice(attr.dataTypeFormat!.length, 0, {
            key: attr.name,
            operators: [':', '!:', '=', '!='],
            propertyLabel: attr.displayName!,
            groupValuesLabel: `${attr.displayName} values`,
          });

          uniqueValues.forEach((value) => {
            options.push({
              propertyKey: attr.name,
              value: value.toString(),
              label: value.toString(),
            });
          });
        }
      });

    return { filteringProperties: properties, filteringOptions: options };
  }, [attributesMetadata, items]);

  const handleFilterChange = useCallback(
    ({ detail }: { detail: PropertyFilterProps.Query }) => {
      setQuery(detail);
      onFilterChange(detail);
      sessionStorage.setItem('savedFilterQuery', JSON.stringify(detail));
    },
    [onFilterChange]
  );

  useEffect(() => {
    // Apply saved filters on component mount
    const savedQuery = sessionStorage.getItem('savedFilterQuery');
    if (savedQuery) {
      const parsedQuery = JSON.parse(savedQuery);
      setQuery(parsedQuery);
      onFilterChange(parsedQuery);
    }
  }, [onFilterChange]);

  return (
    <>
      <PropertyFilter
        onChange={handleFilterChange}
        query={query}
        filteringProperties={filteringProperties}
        filteringOptions={filteringOptions}
        filteringPlaceholder="Find resources"
        tokenLimit={2}
        countText={`Matched resources: ${filteredItems.length} of ${items.length}`}
        i18nStrings={{
          applyActionText: 'Apply',
          tokenLimitShowMore: 'Show More',
          tokenLimitShowFewer: 'Show Fewer',
          clearFiltersText: 'Clear filters',
          operationAndText: 'and',
          operationOrText: 'or',
          filteringAriaLabel: 'Filter resources',
          dismissAriaLabel: 'Dismiss',
          groupValuesText: 'Values',
          groupPropertiesText: 'Properties',
          operatorsText: 'Operators',
          removeTokenButtonAriaLabel: (token) =>
            `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
          enteredTextLabel: (text) => `Use: "${text}"`,
        }}
      />
    </>
  );
};
